import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faPaperPlane, faLocationDot } from '@fortawesome/free-solid-svg-icons';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { collection, query, getDocs, where } from 'firebase/firestore';
import { getDatabase, ref, onValue, push, serverTimestamp, update } from 'firebase/database';
import swal from 'sweetalert';
import sampleImage from '../Images/defprofile.png';
import { db as firestoreDb } from '../firebase';

function MessagesComp() {
  const auth = getAuth();
  const realtimeDb = getDatabase();
  const [usersList, setUsersList] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [showMessageDiv, setShowMessageDiv] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [searchTerm, setSearchTerm] = useState('');

  // Fetch all active chats
  useEffect(() => {
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        const chatsRef = ref(realtimeDb, 'chats');
        onValue(chatsRef, async (snapshot) => {
          if (snapshot.exists()) {
            const allChats = snapshot.val();
            const chatKeys = Object.keys(allChats).filter((key) =>
              key.includes(auth.currentUser.uid)
            );

            // Extract user IDs from chat keys
            const userIds = chatKeys
              .map((key) =>
                key.replace(`${auth.currentUser.uid}_`, '').replace(`_${auth.currentUser.uid}`, '')
              )
              .filter((id, index, self) => self.indexOf(id) === index);

            // Fetch user details and unread message status
            const userPromises = userIds.map((id) =>
              getDocs(query(collection(firestoreDb, 'users'), where('userId', '==', id)))
            );

            const unreadStatusPromises = userIds.map((id) => {
              const chatRef = ref(realtimeDb, `chats/${auth.currentUser.uid}_${id}`);
              return new Promise((resolve) => {
                onValue(chatRef, (snapshot) => {
                  if (snapshot.exists()) {
                    const messages = Object.values(snapshot.val());
                    const hasUnread = messages.some(
                      (msg) => msg.receiverId === auth.currentUser.uid && !msg.isRead
                    );
                    resolve({ userId: id, hasUnread });
                  } else {
                    resolve({ userId: id, hasUnread: false });
                  }
                });
              });
            });

            const [userSnapshots, unreadStatuses] = await Promise.all([
              Promise.all(userPromises),
              Promise.all(unreadStatusPromises),
            ]);

            const usersWithChats = userSnapshots
              .filter((snapshot) => snapshot.docs.length > 0)
              .map((snapshot) => ({
                id: snapshot.docs[0].id,
                ...snapshot.docs[0].data(),
              }));

            const usersWithUnread = usersWithChats.map((user) => ({
              ...user,
              hasUnread: unreadStatuses.find((status) => status.userId === user.userId)?.hasUnread || false,
            }));

            setUsersList(usersWithUnread);
            setFilteredUsers(usersWithUnread);
          }
        });
      }
    });
  }, []);

  // Fetch chat messages
  useEffect(() => {
    if (selectedUser) {
      const chatRef = ref(realtimeDb, `chats/${auth.currentUser.uid}_${selectedUser.id}`);
      onValue(chatRef, (snapshot) => {
        const data = snapshot.val();
        if (data) {
          const formattedMessages = Object.entries(data).map(([key, value]) => ({
            id: key,
            ...value,
          }));
          setMessages(formattedMessages);

          // Mark messages as read
          formattedMessages.forEach((msg) => {
            if (msg.receiverId === auth.currentUser.uid && !msg.isRead) {
              const msgRef = ref(
                realtimeDb,
                `chats/${auth.currentUser.uid}_${selectedUser.id}/${msg.id}`
              );
              update(msgRef, { isRead: true });
            }
          });
        } else {
          setMessages([]);
        }
      });
    }
  }, [selectedUser, realtimeDb, auth]);

  const chatWithUser = (user) => {
    setSelectedUser(user);
    setShowMessageDiv(true);
  };

  const sendMessage = () => {
    if (newMessage.trim()) {
      const messageData = {
        text: newMessage,
        senderId: auth.currentUser.uid,
        receiverId: selectedUser.id,
        timestamp: serverTimestamp(),
        isRead: false,
      };

      const chatRef = ref(realtimeDb, `chats/${auth.currentUser.uid}_${selectedUser.id}`);
      const receiverChatRef = ref(realtimeDb, `chats/${selectedUser.id}_${auth.currentUser.uid}`);

      push(chatRef, messageData);
      push(receiverChatRef, messageData);

      setNewMessage('');
    } else {
      swal('Error', 'Message cannot be empty!', 'error');
    }
  };

  const handleSearch = (e) => {
    const term = e.target.value.toLowerCase();
    setSearchTerm(term);

    const filtered = usersList.filter((user) =>
      user.bio.toLowerCase().includes(term) || user.course.toLowerCase().includes(term)
    );
    setFilteredUsers(filtered);
  };

  return (
    <div className="messagesComponent">
      <div className="messagesLeft">
        <h5>Messages</h5>
        <div className="messCont">
          <input
            placeholder="Search Direct Messages"
            value={searchTerm}
            onChange={handleSearch}
          />
          <FontAwesomeIcon icon={faSearch} className="messSearchIcon" />
        </div>
        <div className="messUsersDiv">
          {filteredUsers.map((user) => (
            <div
              key={user.id}
              className="messUserItem"
              onClick={() => chatWithUser(user)}
            >
              <div className="messUserItemProf">
                <img src={user.profilePic || sampleImage} alt="Profile" />
                {user.hasUnread && <span className="notificationIcon"></span>}
              </div>
              <div>
                <h6>{user.bio}</h6>
                <p>{user.course}</p>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="messagesRight">
        {!showMessageDiv && (
          <div className="messRightDefault">
            <h3>Select a message</h3>
            <p>Choose from your existing conversations or start a new one.</p>
          </div>
        )}

        {showMessageDiv && (
          <div className="startAmessage">
            <div className="startAmessageTop">
              <div className="chatWithUser">
                <img
                  src={selectedUser.profilePic || sampleImage}
                  alt="Profile"
                  className="chatWithUserImage"
                />
                <div>
                  <h6>{selectedUser.bio}</h6>
                  <p>
                    <FontAwesomeIcon icon={faLocationDot} /> {selectedUser.nationality}
                  </p>
                </div>
              </div>
            </div>
            <div className="startAmessageContent">
              {messages.map((msg, index) => (
                <div
                  key={index}
                  className={`message ${
                    msg.senderId === auth.currentUser.uid ? 'myMessage' : 'otherMessage'
                  }`}
                >
                  <div className="messageText">{msg.text}</div>
                  <div className="messageTimestamp">
                    {new Date(msg.timestamp).toLocaleString('en-US', {
                      month: 'short',
                      day: 'numeric',
                      year: 'numeric',
                      hour: 'numeric',
                      minute: 'numeric',
                      hour12: true,
                    })}
                    {" "}
                    <span className={`messageStatus ${msg.isRead ? 'read' : 'unread'}`}>
                      {msg.isRead ? 'Read' : 'Unread'}
                    </span>
                  </div>
                </div>
              ))}
            </div>
            <div className="startAmessageChat">
              <textarea
                className="writeMessageInput"
                placeholder="Write a message..."
                rows="2"
                value={newMessage}
                onChange={(e) => setNewMessage(e.target.value)}
              />
              <div className="sendButton" onClick={sendMessage}>
                <FontAwesomeIcon icon={faPaperPlane} className="sendIcon" />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default MessagesComp;