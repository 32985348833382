import React, { useRef, useState }  from 'react'
import logo from '../Images/logo-main-black.png'
import {NavLink } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBuilding, faComment, faFileInvoice, faHome, faLightbulb, faMessage} from '@fortawesome/free-solid-svg-icons'
import { faUser} from '@fortawesome/free-solid-svg-icons'
import { faUsers} from '@fortawesome/free-solid-svg-icons'
import { faBookmark} from '@fortawesome/free-solid-svg-icons'
import { faBriefcase} from '@fortawesome/free-solid-svg-icons'
import { faCoffee} from '@fortawesome/free-solid-svg-icons'
import { faCalendarDay} from '@fortawesome/free-solid-svg-icons'
import { faUsd} from '@fortawesome/free-solid-svg-icons'
import { faMagic} from '@fortawesome/free-solid-svg-icons'
import { faVideoCamera} from '@fortawesome/free-solid-svg-icons'
import { faAnchor} from '@fortawesome/free-solid-svg-icons'
import { faBicycle } from '@fortawesome/free-solid-svg-icons';

// firebase
import {db} from '../firebase';
import { getAuth, onAuthStateChanged } from "firebase/auth";

//routers
import {useNavigate } from "react-router-dom";

function Leftmenu() {

    let navigate = useNavigate();

    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {

        if (user) {

        } else {
        // User is signed out
        navigate("/login")
        }
    })

  return (
    <div className='leftNav'>
   
        <img src={logo} alt="" className='dashnavLogo' />
      
      
        <br />
        <p>HOME</p>
        

        <NavLink to="/dashboard" className='leftNavLinks leftLinkCont d-flex align-items-center' activeclassname="active">
            <FontAwesomeIcon icon={faHome} className="laftNavIcon activelaftNavIcon"/>
            Dashboard
        </NavLink>

        <NavLink to="/academic" className='leftNavLinks leftLinkCont d-flex align-items-center' activeclassname="active">
            <FontAwesomeIcon icon={faBicycle} className="laftNavIcon"/>
            My Courses
        </NavLink>

        <NavLink to="/my-rooms" className='leftNavLinks leftLinkCont d-flex align-items-center' activeclassname="active">
            <FontAwesomeIcon icon={faVideoCamera} className="laftNavIcon"/>
            Live Classes
        </NavLink>

        <NavLink to="/tasks" className='leftNavLinks leftLinkCont d-flex align-items-center' activeclassname="active">
            <FontAwesomeIcon icon={faBookmark} className="laftNavIcon"/>
            Assigments   
            <span className='leftLinkNotif'>0</span>   
        </NavLink>

        <NavLink to="/teams" className='leftNavLinks leftLinkCont d-flex align-items-center' activeclassname="active">
            <FontAwesomeIcon icon={faUsers} className="laftNavIcon"/>
            Discussion Forums
        </NavLink> 


        {/* className={`leftLinkCont d-flex align-items-center ${activeMenu3}`} */}



        <p>UPDATES & HELP</p>


        <NavLink to="/fee-invoices" className='leftNavLinks leftLinkCont d-flex align-items-center' activeclassname="active">
            <FontAwesomeIcon icon={faFileInvoice} className="laftNavIcon "/>
            Fee Invoices
        </NavLink>

        <NavLink to="/fee-report" className='leftNavLinks leftLinkCont d-flex align-items-center' activeclassname="active">
            <FontAwesomeIcon icon={faUsd} className="laftNavIcon "/>
            Fee Payments
        </NavLink>

        <NavLink to="/class-reports" className='leftNavLinks leftLinkCont d-flex align-items-center' activeclassname="active">
            <FontAwesomeIcon icon={faCalendarDay} className="laftNavIcon "/>
            Class Report
        </NavLink>

        <NavLink to="/messages" className='leftNavLinks leftLinkCont d-flex align-items-center' activeclassname="active">
            <FontAwesomeIcon icon={faComment} className="laftNavIcon"/>
            Messages
        </NavLink>


        <p>RESEARCH & DEV</p>

        <NavLink to="/assigned-profects" className='leftNavLinks leftLinkCont d-flex align-items-center' activeclassname="active">
            <FontAwesomeIcon icon={faCoffee} className="laftNavIcon"/>
            Projects Hub
        </NavLink>

        <NavLink to="/startups" className='leftNavLinks leftLinkCont d-flex align-items-center' activeclassname="active">
            <FontAwesomeIcon icon={faLightbulb} className="laftNavIcon"/>
            My Startups
        </NavLink>

        <NavLink to="/startup-corner" className='leftNavLinks leftLinkCont d-flex align-items-center' activeclassname="active">
            <FontAwesomeIcon icon={faBriefcase} className="laftNavIcon"/>
            Startup Corner
        </NavLink>

        <NavLink to="/job-board" className='leftNavLinks leftLinkCont d-flex align-items-center' activeclassname="active">
            <FontAwesomeIcon icon={faBuilding} className="laftNavIcon"/>
            Job Board
        </NavLink>
        
        <p>Account</p>
        <NavLink to="/profile" className='leftNavLinks leftLinkCont d-flex align-items-center' activeclassname="active">
            <FontAwesomeIcon icon={faUser} className="laftNavIcon "/>
            Profile
        </NavLink>


      

    </div>
  )
}

export default Leftmenu