import React from 'react'
import NavBar from '../Components/NavBar' 
import liveClass from '../Images/live.svg'
import theClasses from '../Images/classes.svg'
import theStudents from '../Images/students.svg'
import theWorld from '../Images/world.svg' 


function Features() {
  return (
    <div>
      <div className='headerSectionHome'>
            <div className='opacity2'></div>
            <NavBar/>
            <div className='headerHomeText'>
                <h6>A New Era of Learning</h6>
                <h4>Features</h4>
            </div>
        </div>

        <section className='section4'>
          <div className='sec4a'>
            <h5>Learn Smarter, Succeed Faster</h5>
            <h1>Your Gateway to Personalized and Interactive Online Learning</h1>
            <p>
              Ubunifu College Smart Campus is a state-of-the-art platform designed to replicate the traditional classroom experience online. With live, interactive teaching and AI-driven personalization, it bridges the gap between physical and digital learning, empowering students to achieve their goals in a dynamic, collaborative environment.
            </p>
            <a href='https://ubunifucollege.com/apply'>Sign Up</a>

          </div>

          <div className='sec4b'>

            <div className='sec4bDivs'>
              <img src={liveClass} alt="" />
              <h6>Live Interactive Classes</h6>
              <p>Real-time lessons for seamless instructor-student engagement</p>
            </div>

            <div className='sec4bDivs'>
              <img src={theClasses} alt="" />
              <h6>Personal Language Model (PLM)</h6>
              <p>Personalized guidance through our adaptive PLM</p>
            </div>

            <div className='sec4bDivs'>
              <img src={theStudents} alt="" />
              <h6>24/7 AI Tutor Assistance</h6>
              <p>Round-the-clock support for assignments and tasks prep.</p>
            </div>

            <div className='sec4bDivs'>
              <img src={theWorld} alt="" />
              <h6>Global Collaboration Tools</h6>
              <p>Connect with peers for group projects and discussions.</p>
            </div>

          </div>
        </section>

        <section className='section3'>
        <div className='opacity2'></div>
        <div className='section3Cont'>
          <h2>Are your dreams big enough?</h2>
          <a href='https://ubunifucollege.com/apply'>GET STARTED TODAY</a>  
        </div>

      </section>
      

    </div>
  )
}

export default Features