import React, { useRef, useState , useEffect} from 'react'
import logo from '../Images/logo-main-black.png'
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { Link, useNavigate } from "react-router-dom";
import Spinner from 'react-bootstrap/Spinner';
import Alert from 'react-bootstrap/Alert'
import '../firebase';
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope} from '@fortawesome/free-solid-svg-icons'

function Recoverpass() {
    const emailRef = useRef();
    const [showAlertError, setShowAlertError] = useState(false)
    const [showAlert, setShowAlert] = useState(false);
    const [showProgress, setShowProgress] = useState(false);
    const [showSignInbtn, setShowSignInbtn] = useState(true);

    const [therrorMessage, setErrorMessage] = useState("");
    const [thesuccessMessage, setSuccessMessage] = useState("");
    const videoRef = useRef(null); // Reference for the video element


    function passwordReset(){
        setShowAlert(false);
        setShowAlertError(false);
        setShowSignInbtn(false)
        setShowProgress(true);

        const email = emailRef.current.value;
        const auth = getAuth();
        sendPasswordResetEmail(auth, email)
        .then(() => {
            // Password reset email sent!
            setSuccessMessage("Password reset email sent successfuly. Sign in to your email and follow the link to reset your password");
            setShowAlert(true);
            setShowSignInbtn(true)
            setShowProgress(false);
        })
        .catch((error) => {

            const errorCode = error.code;
            let errorMessage;
            switch (errorCode) {
            case "auth/user-not-found":
                errorMessage = "No user found with this email address.";
                break;
            case "auth/invalid-email":
                errorMessage = "Invalid email format. Please check and try again.";
                break;
            case "auth/too-many-requests":
                errorMessage = "Too many requests. Please wait and try again later.";
                break;
            default:
                errorMessage = "An error occurred. Please try again.";
            }

            setErrorMessage(errorMessage) 
            setShowAlertError(true);
            setShowProgress(false);
            setShowSignInbtn(true)
            // ..
        });
    }

    useEffect(() => {
        // Attempt to programmatically play the video
        if (videoRef.current) {
          videoRef.current
            .play()
            .catch(error => console.error("Autoplay blocked:", error));
        }
    }, []); // Runs once after the component mounts 

  return (
    <div className='d-flex loginContainer'>
        <div className='resetleft'>
            <Link to="/"><img src={logo} alt="" className='loginLogo' /></Link>        
            <h5 className='welTxt'>Reset Password</h5>  
            <h6>Enter your email to receive the password reset link</h6> 

            <Form.Label className="inputLabel">Email</Form.Label>
            <InputGroup className="mb-3">               
            <InputGroup.Text id="basic-addon1">
                <FontAwesomeIcon icon={faEnvelope} className="theLoginIcon"/>
            </InputGroup.Text>
                    <Form.Control
                    placeholder="Enter your email"
                    aria-label="Enter your email"
                    type="email"
                    aria-describedby="basic-addon1"
                    className='loginForm'
                    ref={emailRef}
                    />
            </InputGroup> 

            {showAlert && 
                <Alert variant={'success'}  >
                    {thesuccessMessage}
                </Alert>
            }

            {showAlertError && 
                <Alert variant={'danger'}  >
                    {therrorMessage}
                </Alert>
            }
            
            <p className="text-muted"><Link to="/login" className='fgPassLink text-muted'>Sign In</Link></p>
            {showSignInbtn && 
                <button variant="primary" onClick={passwordReset} type="submit" className='signInbtnn'>
                    Send Reset Link
                </button>  
            }

            {showProgress && 
                <Button variant="primary" disabled className='signInbtnnLoading'>
                    <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    />
                    <span> Submitting...</span>
                </Button>
            }
        </div>  
        
        <div className='loginLeft'>
        <video
          ref={videoRef}
          src="https://res.cloudinary.com/ubunifu/video/upload/v1607186910/pexels-rodnae-productions-5923291_jd1rwl.mp4"
          muted
          autoPlay
          loop
          playsInline
        ></video>
      </div>


    </div>
  )
}

export default Recoverpass