import React from 'react'
import NavBar from '../Components/NavBar'


function HowItWorks() {
  return (
    <div>
        <div className='headerSectionHome'>
            <div className='opacity2'></div>
            <NavBar/>
            <div className='headerHomeText'>
                <h6>Redefining Digital Learning</h6>
                <h4>How It Works</h4>
            </div>
        </div>

        <section className='section6'>
          <div className='sec6div1'>
            <div className='sec6div1A'></div>
          </div>
          <div className='sec6div2'>
            <h5>Re-imagining how every student learns</h5>
            <h1>Ubunifu Smart Campus</h1>
            <p>Ubunifu College Smart Campus is a cutting-edge, AI-powered platform that brings the traditional classroom experience to life on the web. Designed to support live, interactive teaching, it allows instructors and students to log in and engage in real-time lessons from anywhere, creating a collaborative and immersive educational environment. The platform bridges the gap between physical and digital classrooms, making learning accessible, interactive, and effective. <br /> <br />

            A standout feature of the Smart Campus is our Personal Language Model (PLM)—an innovative AI system built to revolutionize adaptive learning. The PLM intelligently analyzes how each student learns, identifies their strengths and challenges, and provides tailored support to help them excel. It acts as a personal mentor, offering insights and guidance to ensure students stay on track and achieve their educational goals. <br /> <br />

            Ubunifu College Smart Campus isn't just an online learning tool—it's a comprehensive virtual ecosystem that combines real-time instruction with AI-driven personalization, empowering students to reach their full potential while redefining what learning can be in the digital age.</p>
          </div>
        </section>
        
        <section className='section5'>

        <div className='sec5div1'></div>
        <div className='sec5div2'>
          <h5>Personal Language Model (PLM) - Adaptive Learning</h5>
          <p>Having taught students of all ages coding, we come to understand that every student has a unique way of learning. Recognizing this, we cannot apply a one-size-fits-all approach to education. This philosophy is a cornerstone of Ubunifu College, where we proudly boast a 95% student success rate. We invest time in understanding each student’s learning style, and we have integrated this personalized approach into our AI-powered Personal Language Model (PLM), designed to adapt to individual learning needs and support students in achieving their goals.</p>

        </div>
        <div className='sec5div3'></div>
        <div className='sec5div4'>
          <h5>Beyond the Classroom: AI-Powered Support and Global Collaboration</h5>
          <p>Learning extends beyond the classroom with the support of our AI Tutor, an intelligent guide available 24/7 to assist students in understanding concepts, and completing assignments all tailored to their learning styles. Acting like a personal tutor, the AI Tutor offers real-time, interactive guidance anytime, anywhere.

The platform promotes global collaboration, connecting students worldwide for group projects, shared workspaces, and live discussions, combining AI support with real-world teamwork to help students achieve their goals and excel in their careers.
          </p>

        </div>

</section>


    </div>
  )
}

export default HowItWorks