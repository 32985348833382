import './App.css';
import './Respdesign.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Routes, Route, BrowserRouter } from "react-router-dom";
import { LocationProvider } from './LocationContext'; // Import the LocationProvider
import { useEffect } from 'react';
import { getAuth, onAuthStateChanged } from "firebase/auth";

//import Pages
import Home from './Pages/Home';
import Login from './Pages/Login';
import Recoverpass from './Pages/Recoverpass';
import Dashboard from './Dashboard/Dashboard';
import Academic from './Dashboard/Academic';
import TheTeams from './Dashboard/TheTeams';
import AssTasks from './Dashboard/AssTasks';
import MyStartups from './Dashboard/MyStartups';
import ClientProjects from './Dashboard/ClientProjects';
import FeeReports from './Dashboard/FeeReports';
import MyProfile from './Dashboard/MyProfile';
import AiAssistant from './Dashboard/AiAssistant';
import DarasaReports from './Dashboard/DarasaReports';
import EditAccount from './Dashboard/EditAccount';
import Complete from './Pages/Complete';
import ClassSession from './Dashboard/ClassSession';
import GroupCall from './Dashboard/GroupCall';
import AddStartup from './Dashboard/AddStartup';
import MyRooms from './Dashboard/MyRooms';
import ClassCalls from './Dashboard/ClassCalls';
import Chat from './Dashboard/Chat';

// Instructor Account
import DashboardIn from './Instructor/DashboardIn';
import MyClasses from './Instructor/MyClasses';
import Students from './Instructor/Students';
import MyChannels from './Instructor/MyChannels';
import Workshops from './Instructor/Workshops';
import ClientProjo from './Instructor/Clientprojects';
import Studentsreposts from './Instructor/Studentsreposts';
import MyThoughts from './Instructor/MyThoughts';
import AiAssistantIn from './Instructor/AiAssistant';
import ProfileIn from './Instructor/Profile';
import Updateaccount from './Instructor/Updateaccount';
import AllCalls from './Instructor/AllCalls';
import Invoices from './Dashboard/Invoices';


import { getDatabase, ref, onDisconnect, set } from "firebase/database";
import { app } from './firebase.js';
import Messages from './Dashboard/Messages.js';
import JobBoard from './Dashboard/JobBoard.js';
import StartupCorner from './Dashboard/StartupCorner.js';
import HowItWorks from './Pages/HowItWorks.js';
import Features from './Pages/Features.js';
import LearningOutcomes from './Pages/LearningOutcomes.js';
import Downloads from './Pages/Downloads.js';


function App() {

  const auth = getAuth();

  useEffect(() => {
    const db = getDatabase(app);
    // Listen for changes in authentication state
    onAuthStateChanged(auth, (user) => {
      if (user) {
        const userId = user.uid;
        const userStatusRef = ref(db, `/status/${userId}`);

        // User is online
        const onlineStatus = {
          state: "online",
          last_changed: Date.now(),
          userId:userId
        };

        const offlineStatus = {
          state: "offline",
          last_changed: Date.now(),
          userId:userId
        };

        // Set user as online when connected
        set(userStatusRef, onlineStatus);

        // Set user as offline automatically when disconnected
        onDisconnect(userStatusRef).set(offlineStatus);
      }
    });
  }, [auth]);

  return (
    <div>
        <LocationProvider>
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/login" element={<Login />} />
              <Route path="/recover" element={<Recoverpass />} />
              <Route path="/how-it-works" element={<HowItWorks />} />
              <Route path="/features" element={<Features />} />
              <Route path="/student-experience" element={<LearningOutcomes />} />
              <Route path="/downloads" element={<Downloads />} />

              {/* STUDENTS ACCOUNT */}
              <Route path="dashboard" element={<Dashboard />} />
              <Route path="academic" element={<Academic />} />
              <Route path="teams" element={<TheTeams />} />
              <Route path="tasks" element={<AssTasks />} />
              <Route path="startups" element={<MyStartups />} />
              <Route path="assigned-profects" element={<ClientProjects />} />
              <Route path="fee-report" element={<FeeReports />} />
              <Route path="class-reports" element={<DarasaReports />} />
              <Route path="ai-assistant" element={<AiAssistant />} />
              <Route path="profile" element={<MyProfile />} />            
              <Route path="edit-profile" element={<EditAccount />} />
              <Route path="complete" element={<Complete />} />
              <Route path="onsession" element={<ClassSession />} />
              <Route path="group-call" element={<GroupCall />} />
              <Route path="add-startup" element={<AddStartup />} />
              <Route path="my-rooms" element={<MyRooms />} />
              <Route path="class-session" element={<ClassCalls />} />
              <Route path="chat" element={<Chat />} />
              <Route path="fee-invoices" element={<Invoices />} />
              <Route path="messages" element={<Messages />} />

              <Route path="startup-corner" element={<StartupCorner />} />
              <Route path="job-board" element={<JobBoard />} />


              
              

              

              {/* INSTRUCTOR ACCOUNT*/}
              <Route path="in/dashboard" element={<DashboardIn />} />
              <Route path="in/my-classes" element={<MyClasses />} />
              <Route path="in/students" element={<Students />} />
              <Route path="in/channels" element={<MyChannels />} />
              <Route path="in/workshops" element={<Workshops />} />
              <Route path="in/assigned-profects" element={<ClientProjo />} />
              <Route path="in/students-reports" element={<Studentsreposts />} />
              <Route path="in/my-thoughts" element={<MyThoughts />} />
              <Route path="in/ai-assistant" element={<AiAssistantIn />} />
              <Route path="in/profile" element={<ProfileIn />} />
              <Route path="in/edit-profile" element={<Updateaccount />} />
              <Route path="in/class-session" element={<AllCalls />} />
              


            </Routes>       
          </BrowserRouter>    
        </LocationProvider> 
    </div>
  );
}

export default App;