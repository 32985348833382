import React, { useState, useEffect, useRef } from 'react'
import NavBar from '../Components/NavBar'
import NewNavBar from '../Components/NewNavBar'
import Footer from '../Components/Footer'
import icoOne from '../Images/ico1.svg'
import icoTwo from '../Images/ico2.svg'
import icoThree from '../Images/ico3.svg'
import icoFour from '../Images/ico4.svg'
import icoFive from '../Images/ico5.svg'
import icoSix from '../Images/ico6.svg'
import icoSeven from '../Images/ico7.svg'
import icoEight from '../Images/ico8.png'
import icoNine from '../Images/ico9.png'
import icoTen from '../Images/ico10.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCirclePlay, faPhone, faCircle} from '@fortawesome/free-solid-svg-icons'
import { faArrowDown} from '@fortawesome/free-solid-svg-icons'
import {useNavigate, Link } from "react-router-dom";


import facebookIcon from '../Images/facebook.svg'
import instagramIcon from '../Images/instagram.svg'
import twitterIcon from '../Images/twitter.svg'
import linkedInIcon from '../Images/linkedin.svg'
import githubIcon from '../Images/gitgubnew.svg'
import whatsappIcon from '../Images/whatsapp.svg'

function Home() {

  const videoRef = useRef(null); // Reference for the video element

  //instantiating use navigate
  let navigate = useNavigate();
  //naigating to ogin page
  function goToLogin(){
      navigate("/login");
  }

  useEffect(() => {
    // Attempt to programmatically play the video
    if (videoRef.current) {
      videoRef.current
        .play()
        .catch(error => console.error("Autoplay blocked:", error));
    }
}, []); // Runs once after the component mounts


  return (
    <div>
      
      <section className='Hero'>
          <video 
            ref={videoRef}
            src='https://res.cloudinary.com/ubunifu/video/upload/v1607186910/pexels-rodnae-productions-5923291_jd1rwl.mp4' 
            className='theVideo' 
            muted 
            autoPlay 
            loop 
            playsInline 
            loading="lazy">
          </video>
          <div className='opacity'></div>
          <NavBar/>
          {/* <NewNavBar/> */}
          <div className='ycontent1'>
            <a href="https://github.com/Ubunifu-College"><img src={githubIcon} alt="" className='footerIcons'/></a>
            <a href="https://www.instagram.com/ubunifucollege"><img src={instagramIcon} alt="" className='footerIcons'/></a>
            <a href="https://x.com/ubunifucollege"><img src={twitterIcon} alt="" className='footerIcons'/></a>
            <a href="https://www.facebook.com/ubunifucollege/"><img src={facebookIcon} alt="" className='footerIcons'/></a>
            <a href="https://www.linkedin.com/company/ubunifucollege"><img src={linkedInIcon} alt="" className='footerIcons'/></a> 
            <a href="https://wa.me/254727634101"><img src={whatsappIcon} alt="" className='footerIcons'/></a>   
          </div>
          <div className='xContent'>
            <h1>SMART CAMPUS</h1>
            <p>
              
              This is an AI-powered, live interactive classroom platform designed to replicate the traditional classroom experience on the web. Instructors and students from around the world log in and engage in real-time lessons, fostering a dynamic and collaborative global learning environment.
            </p>
            <div className='d-flex'>
              <span className='countryCont'><img src={icoSix} alt="" /></span>
              <span className='countryCont'><img src={icoOne} alt="" /></span>
              <span className='countryCont'><img src={icoSeven} alt="" /></span>
              <span className='countryCont'><img src={icoFour} alt="" /></span>
              <span className='countryCont'><img src={icoFive} alt="" /></span>
              <span className='countryCont'><img src={icoNine} alt="" /></span>
              <span className='countryCont'><img src={icoTen} alt="" /></span>       
            </div>
            <button className='signInbtn' onClick={goToLogin}>Sign In <FontAwesomeIcon icon={faCirclePlay} className="theGrad"/></button>

          </div>
          <div className='ycontent2'>
            <p><span><FontAwesomeIcon icon={faCircle}/></span>+254727634101</p>
          </div>

          <Link to="/how-it-works">
            <div className='scrollDown'>
              <FontAwesomeIcon icon={faArrowDown} className="theScroll"/>
            </div>
          </Link>

      </section>




    </div>
  )
}

export default Home