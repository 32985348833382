import React , { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faVideoCamera} from '@fortawesome/free-solid-svg-icons'
import { faMapMarkerAlt} from '@fortawesome/free-solid-svg-icons'
import linkedInProfileIco from '../Images/linkedinProfile.svg'
import githubProfileIco from '../Images/gitgubProfile.svg'
import twitterProfileIco from '../Images/twitterProfile.svg'

// firebase
import {db} from '../firebase';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { collection, query,limit, getDocs, where } from "firebase/firestore";

//sweetalert
import swal from 'sweetalert';

//routers
import {useNavigate } from "react-router-dom";

import coverPhotoContImage from '../Images/default-cover.gif'
import profilePictureContImage from '../Images/defprofile.png'



function TeamsComp() {

  const auth = getAuth();
  let navigate = useNavigate();
  const [studentsList, setStudentList] = useState([]);
  const [teamsList, setTeamsList] = useState([]);
  const [usersList, setUsersList] = useState([]);

  //profile pic & cover
  const [userProfilePicture, setProfilePicture] = useState(profilePictureContImage);
  const [userCoverPicture, setCoverPicture] = useState(coverPhotoContImage);

  //start video call
  function startAgroupCall(){

    onAuthStateChanged(auth, (user) => {
        if (user) {
        //if user is logged in 
        const uid = user.uid;

        //get user
        const q = query(collection(db, "users"), where("userId", "==", uid));
        getDocs(q).then((QuerySnapshot)=>{

            QuerySnapshot.forEach((doc)=>{
            const course = doc.data().course;
            const userStatus = doc.data().userStatus;

            if (userStatus === "Student" || userStatus === "Instructor" || userStatus === "admin"){
              navigate("/chat")
            }else{
              swal("Error", "Your Student status is not yet active. Contact admin", "warning");
            }
            
            })
        })
        //end

        } else {
        // User is signed out
        }
    });

  }

  // end video calls
  function openGroupChat(){
    const roomid = "generalgroup";
    navigate('/class-session', { state: { myData: roomid } });
  }


  //fetch all Active Students
  useEffect(() => {

     //check if the user is a student
      onAuthStateChanged(auth, (user) => {
        if (user) {
          //if user is logged in 
          const uid = user.uid;

          //lets get all the teams

          const q = query(collection(db, "users"), where("profilePic", '!=', null), limit(3));
          getDocs(q).then((QuerySnapshot)=>{
            let userItem = [];
            QuerySnapshot.forEach((mydoc)=>{

              const profilePic = mydoc.data().profilePic;
              console.log(profilePic)

              //this is the magic
              const fetchData = async () => {
                const q = query(collection(db, "Teams"));
                let teamsItem = [];
                const querySnapshot = await getDocs(q);
                querySnapshot.forEach((doc) => {



                    userItem.push({ id: mydoc.id, ...mydoc.data() });
                    setUsersList([...userItem]);  


                    const z = query(collection(db, "users"), where("userStatus", "==", "Student"));
                    getDocs(z).then((QuerySnapshot)=>{
                        let myquerysize = QuerySnapshot.size;

                        teamsItem.push({ id: doc.id, ...doc.data(), myquerysize });
                        setTeamsList([...teamsItem]);  
    
                    }) 


                });
              };

              fetchData();
              //end magic
            }) 
          })
       


        } else {
          // User is signed out
          }
      });
      //end
    

  }, []);
  //end fetching active students



  function startChat(){
    swal("Error", "User hasn't enabled this feature", "warning");
  }

  function startStudVidCall(){
    swal("Error", "User hasn't enabled this feature", "warning");
  }




  
  return (
    <div className='teamsComponent'>
        <div className='teamsTopBar'>
            <h5 className='tHide1'>Discussion Forums</h5>
            <h5 className='tHide2'>Discussion Forums</h5>
            {/* <button className='startOnlSessBtn' onClick={startAgroupCall}> <FontAwesomeIcon icon={faVideoCamera} className="liveSessionIcon"/>Join a General Group session</button>
            <button className='startOnlSessBtn2' onClick={startAgroupCall}> <FontAwesomeIcon icon={faVideoCamera} className="liveSessionIcon"/> Join A Group Session</button> */}
        </div>

        <div className='teamsContainer'>

        

             {teamsList.map((myteam) => (
              <div className='channelItem' key={Math.random()} onClick={openGroupChat}>

                <div className='channelItemTop'>

                </div>
                <div className='channelItemCont'>
                  <h5>{myteam.teamName}</h5>
                  <p>{myteam.teamDesc}</p>
                  <div className='profilePlaceHolderCont' >


                    {usersList.map((myusers) =>(
                      <div className='profileChannel' key={Math.random()}>
                      <img src={myusers.profilePic} alt="" />
                      </div>
                    ))}


                    <div className='profileChannel2'>
                        <span>+{myteam.myquerysize}</span>
                    </div>
                  </div>
                </div>              
              </div>
            ))} 


            <div className='clearFixAll'></div>
           



        </div>


        <br /> <br />

    </div>
  )
}

export default TeamsComp