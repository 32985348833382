import React, { useState, useEffect } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import Table from "react-bootstrap/Table";

// Firebase
import { db } from "../firebase";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { collection, query, getDocs, where } from "firebase/firestore";

import { useLocation } from "../LocationContext";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

function FeeComp() {
  // States
  const [courseFeeInstallments, setCourseFeeInstallments] = useState("");
  const [amountPaid, setAmountPaid] = useState("");
  const [amountPaidThisMonth, setAmountPaidThisMonth] = useState("");
  const [monthlyData, setMonthlyData] = useState(Array(12).fill(0)); // Holds payments for each month
  const [studentsList, setStudentList] = useState([]);

  const { location, currency } = useLocation();
  const [currentCurrencyCode, setCurrentCurrencyCode] = useState("USD");
  const [conversionRate, setConversionRate] = useState(1); // Default rate (USD to USD = 1)

  // Currency logic
  useEffect(() => {
    if (location && currency) {
      if (location.country === "KE") {
        setCurrentCurrencyCode("KES");
        setConversionRate(1); // Assuming KES to KES is 1:1
      } else {
        setCurrentCurrencyCode("USD");
        setConversionRate(0.00779); // Example: 1 KES = 0.007 USD
      }
    }
  }, [location, currency]);

  useEffect(() => {
    const fetchData = async () => {
      const auth = getAuth();
      onAuthStateChanged(auth, async (user) => {
        if (user) {
          const uid = user.uid;

          // Fetch student data and income data
          fetchStudentData(uid);
          fetchIncomeData(uid);

          try {
            // Query Firestore
            const q = query(collection(db, "Income"), where("studentId", "==", uid));
            const querySnapshot = await getDocs(q);

            // Transform and update state
            const studentsItem = querySnapshot.docs.map((doc) => ({
              id: doc.id,
              ...doc.data(),
            }));
            setStudentList(studentsItem);
          } catch (error) {
            console.error("Error fetching income data:", error);
          }
        }
      });
    };

    fetchData();
  }, []); // Empty dependency array means it runs only once
 

  // Fetch student course details
  const fetchStudentData = async (uid) => {
    const q = query(collection(db, "users"), where("userId", "==", uid));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      const course = doc.data().course;
      fetchCoursePrice(course);
    });
  };

  // Fetch course price and installment details
  const fetchCoursePrice = async (course) => {
    const q = query(collection(db, "courses"), where("mcourseName", "==", course));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      setCourseFeeInstallments(doc.data().feeInstallments);
    });
  };

  

  // Fetch income data
  const fetchIncomeData = async (uid) => {
    const q = query(collection(db, "Income"), where("studentId", "==", uid));
    const querySnapshot = await getDocs(q);

    let totalIncome = 0;
    let thisMonthIncome = 0;
    const tempMonthlyData = Array(12).fill(0);

    const today = new Date();
    const thisMonth = today.getMonth();
    const thisYear = today.getFullYear();

    querySnapshot.forEach((doc) => {
      const incomeAmount = parseInt(doc.data().incomeAmount);
      const paymentDate = doc.data().paymentDate.toDate();
      const paymentMonth = paymentDate.getMonth();
      const paymentYear = paymentDate.getFullYear();

      // Accumulate income
      totalIncome += incomeAmount;

      // Accumulate monthly data
      if (paymentYear === thisYear) {
        tempMonthlyData[paymentMonth] += incomeAmount;
        if (paymentMonth === thisMonth) {
          thisMonthIncome += incomeAmount;
        }
      }
    });

    setAmountPaid(totalIncome);
    setAmountPaidThisMonth(thisMonthIncome);
    setMonthlyData(tempMonthlyData);
  };

  // Prepare graph data
  const graphData = monthlyData.map((value) => (value * conversionRate).toFixed(2));

  return (
    <div>
      <div className="feeComp">
        <div className="teamsTopBar">
          <h5 className="tHide1">Fee Payments</h5>
          <h5 className="tHide2">Fee Payments</h5>
        </div>
        <br />

        <div className="feelineGraph">
          <Line
            className="theFeeLineGraph"
            datasetIdKey="id"
            data={{
              labels: [
                "Jan",
                "Feb",
                "March",
                "Apr",
                "May",
                "June",
                "July",
                "Aug",
                "Sept",
                "Oct",
                "Nov",
                "Dec",
              ],
              datasets: [
                {
                  id: 1,
                  label: `Fee payment - ${currentCurrencyCode}`,
                  data: graphData,
                  backgroundColor: "rgba(54, 162, 235, 0.2)",
                  borderColor: "rgba(54, 162, 235, 1)",
                  borderWidth: 1,
                },
              ],
            }}
          />
        </div>

        <h6 className="feeTransactionHeading">Payment Transactions</h6>

        <div className="feeTableCont table-responsive">
          <Table striped className="feeTable">
            <thead>
              <tr>
                <th>Date</th>
                <th>Amount</th>
                <th>Payment Method</th>
                <th>Download Receipt</th>
              </tr>
            </thead>
            <tbody>
            {studentsList.map((mystudent) => (
              <tr key={Math.random()}>
                  <td>{mystudent.paymentDate.toDate().toDateString()}</td>
                  <td>
                  {new Intl.NumberFormat("en-US", {
        style: "decimal",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(mystudent.incomeAmount * conversionRate)} {currentCurrencyCode}

                  </td>
                  <td>{mystudent.paymentMethod}</td>
                  <td> <a href={'https://ubunifu.web.app/view-receipt?' + mystudent.invoiceNumber} target="_blank" className='btn btn-warning feeButton' rel="noreferrer">View Receipt</a> </td>
              </tr>
            ))}  
            </tbody>
          </Table>
        </div>
      </div>
    </div>
  );
}

export default FeeComp;
