import React from 'react'
import NavBar from '../Components/NavBar' 

function LearningOutcomes() {
  return (
    <div>
        <div className='headerSectionHome'>
            <div className='opacity2'></div>
            <NavBar/>
            <div className='headerHomeText'>
                <h6>A New Era in Education</h6>
                <h4>Student Experience</h4>
            </div>
        </div>

        <section className='section6'>
          <div className='sec6div2'>
            <h5>A Global Classroom Experience</h5>
            <h1>Harnessing AI to Create an Adaptive, Collaborative, and Interactive Student Experience.</h1>
            <p>The Student Experience at Ubunifu College Smart Campus is centered around creating a dynamic and personalized learning environment where students are empowered to succeed both academically and professionally. With live, interactive classes led by expert instructors, AI-driven adaptive learning tools, and opportunities for global collaboration, students are equipped to achieve their full potential. The platform fosters a supportive, 24/7 learning ecosystem, combining real-time guidance, continuous feedback, and career-oriented skills to prepare students for success in the digital age.</p>
          </div>
          <div className='sec6div1'>
            <div className='sec6div1B'></div>
          </div>
        </section>

    </div>
  )
}

export default LearningOutcomes