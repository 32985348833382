import React from 'react'
import NavBar from '../Components/NavBar'
import theMaggie from '../Images/backmain.jpg'
import theMain2 from '../Images/main2.jpg'


function Downloads() {
  return (
    <div>

      <div className='headerSectionHome'>
          <div className='opacity2'></div>
            <NavBar/>
            <div className='headerHomeText'>
                <h6>Available Downloads</h6>
                <h4>Downloads</h4>
            </div>
        </div>

        <section className='availableDownloads table-responsive'>
        <table className="table table-bordered table-striped">
          <thead>
            <tr>
              <th scope="col">File Name</th>
              <th scope="col">Software Type</th>
              <th scope="col">Operating System</th>
              <th scope="col">Version</th>
              <th scope="col">Download Link</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Ubunifu Smart Campus for Instructors</td>
              <td>Desktop Application</td>
              <td>MacOS</td>
              <td>0.1.0</td>
              <td><a href="https://firebasestorage.googleapis.com/v0/b/ubunifu-college.appspot.com/o/Softwares%2FUbunifu.app.zip?alt=media&token=ef72e579-0e72-4a78-9e33-2050b3a5a525" className="btn btn-primary" download>Download Software</a></td>
            </tr>
 
          </tbody>
        </table>

        </section>




        {/* <section className='aboutSec'>

          <div className='abSec1'>
            <h6>CENTRE FOR AI RESEARCH & DEVELOPMENT</h6>
            <h3>Live Interactive Virtual classes.</h3>
            <p>Get a classroom experience from anywhere you are, interact with fellow students, build and launch amazing products!</p>
            <a href='https://ubunifucollege.com/apply' className='signUpbtn'>Sign Up</a>
          </div>

          <div className='abSec2'>
            <img src={theMain2} alt="" className='boy4'/>
            <div className='lineCont'>           
              <div className='theLine'/> <p> Project Based Learning</p>
            </div>        
          </div>

          <div className='abSec3'>
            <img src={theMaggie} alt="" className='boy4'/>
            <div className='lineCont'>
              <div className='theLine'/> <p>Learn from accomplished Devs</p>
            </div>      
          </div>

        </section> */}


    </div>
  )
}

export default Downloads