import React from 'react'

function JobBoardComp() {
  return (
    <div className='teamsComponent'>
      <div className='teamsTopBar'>
          <h5 className='tHide1'>Job Board</h5>
          <h5 className='tHide2'>Job Board</h5>
      </div>

    </div>
  )
}

export default JobBoardComp