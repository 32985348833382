import React from 'react'
import Dashnav from '../Components/Dashnav'
import Leftmenu from '../Components/Leftmenu'
import InvoicesComp from './InvoicesComp'

function Invoices() {
  return (
    <div>
        <div className='mainContainer'>
            <Leftmenu/>
            <div className='theMainContainer'>
              <Dashnav/>
              <InvoicesComp/>
            </div>
        </div>  
    </div>
  )
}

export default Invoices