import React, { useState, useEffect, useRef } from 'react';
import logo from '../Images/logo-main-white.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faBars } from '@fortawesome/free-solid-svg-icons';
import { useNavigate, Link, NavLink } from 'react-router-dom';  // Make sure useNavigate is imported here
import icoSix from '../Images/ico6.svg';
import { useLocation } from '../LocationContext';  // Ensure this context is properly defined
import { Dropdown } from 'react-bootstrap';  // Importing Bootstrap Dropdown


function NavBar() {
  const { location, currency } = useLocation();  // Ensure that location and currency are provided by the context
  const [isMenuOpen, setIsMenuOpen] = useState(false); // State to manage menu toggle
  const menuRef = useRef(null); // Reference to the dropdown menu
  const [theFlag, setTheFlag] = useState();

  // Update the flag state when currency changes
  useEffect(() => {
    if (currency?.flag) {
      setTheFlag(currency.flag);
    }
  }, [currency]);

    // Close the menu when clicking outside of it
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (menuRef.current && !menuRef.current.contains(event.target)) {
          setIsMenuOpen(false); // Close the menu if clicked outside
        }
      };

      // Add event listener on mount
      document.addEventListener('mousedown', handleClickOutside);

      // Cleanup event listener on unmount
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, []);

  console.log("amen")

  const toggleMenu = () => {
    console.log("open")
    setIsMenuOpen(!isMenuOpen); // This function toggles the state of the menu
  };

  return (
    <div className="navBar">
      {/* Logo */}
      <Link to="/" >
        <img src={logo} alt="Ubunifu College logo" className="logo" />
      </Link>      

      {/* Navigation Menu */}
      <ul className="menu">

        <NavLink to="/" className={({ isActive }) => (isActive ? 'ActiveNewHomeMenu' : 'newHomeMenu')}>
          <p>01</p>
          <h6>HOME</h6>
        </NavLink>

        <NavLink to="/how-it-works" className={({ isActive }) => (isActive ? 'ActiveNewHomeMenu' : 'newHomeMenu')}>
          <p>02</p>
          <h6>HOW IT WORKS</h6>
        </NavLink>

        <NavLink to="/features" className={({ isActive }) => (isActive ? 'ActiveNewHomeMenu' : 'newHomeMenu')}>
          <p>03</p>
          <h6>FEATURES</h6>
        </NavLink>

        <NavLink to="/student-experience" className={({ isActive }) => (isActive ? 'ActiveNewHomeMenu' : 'newHomeMenu')}>
          <p>04</p>
          <h6>STUDENT EXPERIENCE</h6>
        </NavLink>

        <NavLink to="/downloads" className={({ isActive }) => (isActive ? 'ActiveNewHomeMenu' : 'newHomeMenu')}>
          <p>05</p>
          <h6>DOWNLOADS</h6>
        </NavLink>
      </ul>

      {/* Mobile Menu Icon */}
      <p onClick={toggleMenu}>
        <FontAwesomeIcon icon={faBars} className="theBarsIcon" />
      </p> 

       {/* Mobile Dropdown Menu */}
       {isMenuOpen && (
        <div ref={menuRef}>
          <Dropdown.Menu show className="mobileMenuDropdown">
            <Dropdown.Item as={NavLink} to="/" eventKey="1">Home</Dropdown.Item>
            <Dropdown.Item as={NavLink} to="/how-it-works" eventKey="2">How It Works</Dropdown.Item>
            <Dropdown.Item as={NavLink} to="/features" eventKey="3">Features</Dropdown.Item>
            <Dropdown.Item as={NavLink} to="/student-experience" eventKey="4">Student Experience</Dropdown.Item>
            <Dropdown.Item as={NavLink} to="/downloads" eventKey="5">Downloads</Dropdown.Item>
          </Dropdown.Menu>
        </div>
      )}

      {/* Login Button */}
      <Link to="/login" className="newLoginBtns">
        <span className="countryCont2">
          {theFlag ? <img src={theFlag} alt="Flag" /> : <img src={icoSix} alt="Default icon" />}
        </span>
        <span>Login</span>
        <FontAwesomeIcon icon={faArrowRight} className="newLoginbtnsRight" />
      </Link>
    </div>
  );
}

export default NavBar;