import React, { useState, useEffect } from 'react';
import DailyIframe from '@daily-co/daily-js';
// Firebase
import { db } from '../firebase';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { collection, query, getDocs, where, addDoc, updateDoc, serverTimestamp, doc } from 'firebase/firestore';
// Routers
import { useNavigate, useLocation } from 'react-router-dom';

function ClassCalls() {
  const [loggedInUser, setLoggedInUser] = useState("");
  const [loggedInUserId, setLoggedInUserId] = useState("");
  const [sessionDocId, setSessionDocId] = useState(null);
  const [sessionCreated, setSessionCreated] = useState(false); // Flag to track if session has been created

  const auth = getAuth();
  let navigate = useNavigate();



  // Receive data from the previous page
  const location = useLocation();
  const myData = location.state?.myData;

  const roomlink = myData ? "https://ubunifu.daily.co/" + myData : ""; // Ensure myData is available

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        // If user is logged in 
        const uid = user.uid;
        setLoggedInUserId(uid);

        const q = query(collection(db, "users"), where("userId", "==", uid));
        try {
          const QuerySnapshot = await getDocs(q);
          QuerySnapshot.forEach((doc) => {
            const username = doc.data().bio;
            setLoggedInUser(username);
          });
        } catch (error) {
          console.error("Error fetching user data: ", error);
        }
      } else {
        // User is signed out
        navigate("/login");
      }
    });

    return () => unsubscribe(); // Clean up the subscription
  }, [auth, navigate]);

  // Starting the call
  useEffect(() => {
    if (loggedInUser && roomlink && !sessionCreated) {
      let callFrame = DailyIframe.createFrame({
        showLeaveButton: true,
        iframeStyle: {
          position: 'fixed',
          top: '0',
          right: '0',
          width: '100%',
          height: '100%',
        },
      });

      callFrame.setUserName(loggedInUser);

      let myBigSessionDoc;

      callFrame.join({ url: roomlink }).then(async () => {
        console.log("Your session starts now!");

        try {
          const sessionRef = await addDoc(collection(db, 'sessions'), {
            userId: auth.currentUser.uid,
            username: loggedInUser,
            startTime: serverTimestamp(),
            roomlink: roomlink,
          });
          setSessionDocId(sessionRef.id);
          setSessionCreated(true); // Mark session as created
          myBigSessionDoc = sessionRef.id;
        } catch (error) {
          console.error("Error creating session document: ", error);
        }
      });

      callFrame.on('left-meeting', () => {
        console.log("Your session ends now!");
        console.log(myBigSessionDoc);

        console.log("ending..")
          const sessionDocRef = doc(db, 'sessions', myBigSessionDoc); // Ensure the collection name is correct
          updateDoc(sessionDocRef, {
            endTime: serverTimestamp(),
          }).then(() => {
            window.location.href = 'https://live.ubunifucollege.com/dashboard';
          }).catch(error => {
            console.error("Error updating session document: ", error);
          });
        
      });
    }
  }, [loggedInUser, roomlink, auth, sessionDocId, sessionCreated]); // Add sessionCreated to prevent multiple triggers


  

  return (
    <div className='onSessionCont'>
      <p>Loading....</p>
    </div>
  );
}

export default ClassCalls; 