import React from 'react'

function StartupCornerComp() {
  return (
    <div className='teamsComponent'>
      <div className='teamsTopBar'>
          <h5 className='tHide1'>Startup Corner</h5>
          <h5 className='tHide2'>Startup Corner</h5>
      </div>

    </div>
  )
}

export default StartupCornerComp