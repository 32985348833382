import React from 'react'
import Dashnav from '../Components/Dashnav'
import Leftmenu from '../Components/Leftmenu'
import MessagesComp from './MessagesComp'

function Messages() {
  return (
    <div>
        <div className='mainContainer'>
            <Leftmenu/>
            <div className='theMainContainer'>
              <MessagesComp/>
            </div>
        </div>  
    </div>
  )
}

export default Messages