import React, { useRef, useState, useEffect } from 'react'
import logo from '../Images/logo-main-black.png'
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { Link, useNavigate } from "react-router-dom";
import Spinner from 'react-bootstrap/Spinner';
import Alert from 'react-bootstrap/Alert'

//firebase
import {db} from '../firebase';
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { collection, query, getDocs, where } from "firebase/firestore";

//fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope} from '@fortawesome/free-solid-svg-icons'
import { faLock} from '@fortawesome/free-solid-svg-icons'

function Login() {

    let navigate = useNavigate();
    const emailRef = useRef();
    const passwordRef = useRef();
    const [showAlert, setShowAlert] = useState(false);
    const [showProgress, setShowProgress] = useState(false);
    const [showSignInbtn, setShowSignInbtn] = useState(true);
    const [therrorMessage, setErrorMessage] = useState("");
    const videoRef = useRef(null); // Reference for the video element

    function SignInUser(){
        const email = emailRef.current.value;
        const password = passwordRef.current.value;
        const auth = getAuth();

        setShowProgress(true);
        setShowAlert(false);
        setShowSignInbtn(false)

        //sign in a user
        signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
            // Signed in 
            //now lets check who has loggedin 
            //store their user id in the const name below
            let userID = userCredential.user.uid

            const q = query(collection(db, "users"), where("userId", "==", userID));
            getDocs(q).then((QuerySnapshot)=>{
                QuerySnapshot.forEach((doc)=>{

                    let userType = doc.data().userType;

                    if (userType === "Instructor" ){
                        navigate("/in/dashboard")
                    }else if(userType === "student"){
                        navigate("/dashboard")
                    }             
                    
                })
            })

        })
        .catch((error) => {
            //const errorCode = error.code;

            const errorCode = error.code;
            let errorMessage;

            switch (errorCode) {
                case "auth/user-not-found":
                  errorMessage = "No user found with this email.";
                  break;
                case "auth/wrong-password":
                  errorMessage = "Incorrect password. Please try again.";
                  break;
                case "auth/invalid-email":
                  errorMessage = "Invalid email address. Please check and try again.";
                  break;
                default:
                  errorMessage = "An error occurred. Please try again.";
            }

            setShowAlert(true)
            setErrorMessage(errorMessage)    
            setShowProgress(false)
            setShowSignInbtn(true)
        });
    }

    useEffect(() => {
        // Attempt to programmatically play the video
        if (videoRef.current) {
          videoRef.current
            .play()
            .catch(error => console.error("Autoplay blocked:", error));
        }
    }, []); // Runs once after the component mounts


  return (
    <div className='d-flex loginContainer'>
        <div className='loginLeft'>
        <video
          ref={videoRef}
          src="https://res.cloudinary.com/ubunifu/video/upload/v1607186910/pexels-rodnae-productions-5923291_jd1rwl.mp4"
          muted
          autoPlay
          loop
          playsInline
        ></video>
      </div>

        <div className='loginRight'>
            <Link to="/"><img src={logo} alt="" className='loginLogo' /></Link>   
            <h5 className='welTxt'>Welcome back!</h5>   
            <h6>Enter your email address and password to access your Account.</h6> 

            <Form.Label className="inputLabel">Email</Form.Label>
            <InputGroup className="mb-3">               
            <InputGroup.Text id="basic-addon1">
                <FontAwesomeIcon icon={faEnvelope} className="theLoginIcon"/>
            </InputGroup.Text>
                    <Form.Control
                    placeholder="Enter your email"
                    aria-label="Enter your email"
                    type="email"
                    aria-describedby="basic-addon1"
                    className='loginForm'
                    ref={emailRef}
                    />
            </InputGroup> 

            <Form.Label className="inputLabel">Password</Form.Label>
            <InputGroup className="mb-3">               
            <InputGroup.Text id="basic-addon1">
                <FontAwesomeIcon icon={faLock} className="theLoginIcon"/>
            </InputGroup.Text>
                    <Form.Control
                    placeholder="Enter your Password"
                    aria-label="Enter your Password"
                    type="password"
                    aria-describedby="basic-addon1"
                    className='loginForm'
                    ref={passwordRef}
                    />
            </InputGroup> 

            {showAlert && 
                <Alert variant={'danger'}  >
                    {therrorMessage}
                </Alert>
            }
            
            <p className="text-muted"><Link to="/recover" className='fgPassLink text-muted'>Forgot Password?</Link></p>
           
            {showSignInbtn && 
                <button  type="submit" onClick={SignInUser} className='signInbtnn'>
                    Sign In
                </button>  
            }

            {showProgress && 
                <Button variant="primary" disabled  className='signInbtnnLoading' >
                    <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    />
                    <span> Logging in...</span>
                </Button>
            }
        </div>     
    </div>
  )
}

export default Login