import React from 'react'
import Dashnav from '../Components/Dashnav'
import Leftmenu from '../Components/Leftmenu'
import StartupCornerComp from './StartupCornerComp'


function StartupCorner() {
  return (
    <div>
        <div className='mainContainer'>
            <Leftmenu/>
            <div className='theMainContainer'>
              <Dashnav/>
              <StartupCornerComp/>
            </div>
        </div>  
    </div>
  )
}

export default StartupCorner