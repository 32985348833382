import React, { useState, useEffect } from 'react';
// Firebase
import { db } from '../firebase';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { collection, query, getDocs, where } from 'firebase/firestore';
// Routers
import { useNavigate } from 'react-router-dom';

function DarasaComp() {

  const currentMonth = new Date().toISOString().slice(0, 7); // Get the current month in YYYY-MM format
  const [selectedMonth, setSelectedMonth] = useState(currentMonth);
  const [attendanceData, setAttendanceData] = useState({});

  const auth = getAuth();
  let navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        const uid = user.uid;
        const q = query(collection(db, "sessions"), where("userId", "==", uid));

        try {
          const QuerySnapshot = await getDocs(q);

          let fetchedAttendanceData = {};
          QuerySnapshot.forEach((doc) => {
            const sessionData = doc.data();
            const date = sessionData.startTime.toDate().toISOString().slice(0, 10); // Format the date

            // Get the current check-in time
            const checkInTime = sessionData.startTime.toDate();

            // If this is the first log for the day or the check-in time is earlier than the stored one, update it
            if (!fetchedAttendanceData[date] || checkInTime < fetchedAttendanceData[date].checkInTime) {
              fetchedAttendanceData[date] = {
                status: "Present",  // You can add logic to set the status if necessary
                checkInTime, // Store the earliest check-in time
              };
            }
          });

          setAttendanceData(fetchedAttendanceData);
        } catch (error) {
          console.error("Error fetching user data: ", error);
        }
      } else {
        navigate("/login");
      }
    });

    return () => unsubscribe(); // Clean up the subscription
  }, [auth, navigate]);

  const getAttendanceColor = (status) => {
    switch (status) {
      case "Present":
        return "green";
      case "Absent":
        return "red";
      case "Late":
        return "yellow";
      default:
        return "gray";
    }
  };

  const daysInMonth = new Date(
    selectedMonth.split("-")[0],
    selectedMonth.split("-")[1],
    0
  ).getDate();

  const generateCalendar = () => {
    const days = [];
    for (let i = 1; i <= daysInMonth; i++) {
      const date = `${selectedMonth}-${i.toString().padStart(2, "0")}`;
      days.push({
        date,
        ...attendanceData[date], // Add the attendance data for the day
      });
    }
    return days;
  };

  return (
    <div className="darasa-container">
      <h5>My Attendance Report</h5>

      {/* Month Selector */}
      <div className="month-selector">
        <label htmlFor="month">Select Month:</label>
        <input
          type="month"
          id="month"
          value={selectedMonth}
          onChange={(e) => setSelectedMonth(e.target.value)}
        />
      </div>

      {/* Calendar View */}
      <div className="attendance-calendar">
        {generateCalendar().map((day, index) => (
          <div
            key={index}
            className="attendance-day"
            style={{ borderColor: getAttendanceColor(day.status) }}
          >
            <h6>{day.date.split("-")[2]}</h6>
            <p style={{ color: getAttendanceColor(day.status) }}>
              {day.status || "No Data"}
            </p>
            {day.checkInTime && (
              <p className="check-in-time">Check-in: {day.checkInTime.toLocaleTimeString()}</p>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

export default DarasaComp;